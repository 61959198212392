import ResponsiveController from "./ResponsiveController";
import Letters from "./Letters";
import Config from "./Config";
import HandleKeyUp from "./HandleKeyUp";
import Timers from "./Timers";
import KeyPredictor from "./KeyPredictor";
import HandleKeyDown from "./HandleKeyDown";

var handlerClick;
var handlerKeyUp;
var handlerKeyDown;
var handlerTouchStart;
var handlerTouchEnd;
var areaHandleChange;

function play2() {
  return;
  // let bet = Math.floor(Math.random() * 2);
  // var audio = document.getElementById("audio");
  // console.log(bet);
  // audio.currentTime = 0.4;
  // audio.play();
}

handlerClick = (e) => {
  Config.SCREEN_WIDTH = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
  ResponsiveController.setCaretToEnd();
  if (!Letters.allowInput) return;
  ResponsiveController.enableTextArea();
  if (true || !Config.REAL_KEYBOARD){
    if (Letters.totalPrompt !== Config.lastPred){
      HandleKeyDown("Shift");
      HandleKeyUp("Shift");
    }
    document.getElementById("GRAT").classList.remove('is_shaking1');
    document.getElementById("prompted").classList.remove("is_shaking1");
    document.getElementById("caret").classList.remove("explose");
  }
  ResponsiveController.forceFocus();
  ResponsiveController.setCaretToEnd();

};



handlerTouchStart = (e) => {
  play2();
  Config.SCREEN_WIDTH = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
  Config.REAL_KEYBOARD = false;
  if (!Letters.allowInput) return;
  if (Config.TUTO_OPEN) return;

  ResponsiveController.setCaretToEnd();
  ResponsiveController.disableTextArea();
  ResponsiveController.forceFocus();
  document.getElementById("caret").classList.remove("blink");
  document.getElementById("caret").classList.add("explose");
  e.stopImmediatePropagation();
};

handlerTouchEnd = (e) => {
  Config.SCREEN_WIDTH = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
  Config.REAL_KEYBOARD = false;
  if (!Letters.allowInput) return;
  if (Config.TUTO_OPEN) return;

  ResponsiveController.setCaretToEnd();
  if (!Config.REAL_KEYBOARD){
    if (Letters.totalPrompt !== Config.lastPred){
      HandleKeyUp("Shift");
    }
    ResponsiveController.forceFocus();
  }
  document.getElementById("GRAT").classList.remove('is_shaking1');
  document.getElementById("prompted").classList.remove("is_shaking1");
  document.getElementById("caret").classList.remove("explose");
};

handlerKeyDown = (e) => {
  play2();
  Config.SCREEN_WIDTH = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
  ResponsiveController.setCaretToEnd();
  if (!Config.REAL_KEYBOARD){
    return;
  }
  if (e.ctrlKey && ( e.keyCode === 73 || e.keyCode === 67 ) ){
    e.preventDefault();
  }
  if([37, 38, 39, 40, 9].indexOf(e.keyCode) > -1){
    e.preventDefault(); // Disable arrow and tab
  }
  if (!Letters.allowInput) return;
  e.stopImmediatePropagation();
  clearTimeout(Timers.typingTimer);
  clearTimeout(Timers.displayTimer);
  clearTimeout(Timers.matrixInterval);
  clearTimeout(Timers.typingTimer);
  clearTimeout(Timers.caretTimer);
  if (Config.REAL_KEYBOARD){
    ResponsiveController.updateCaret();
    if (e.key !== "Backspace"){
      if (Config.VIBRATION) document.getElementById("GRAT").classList.add("is_shaking1");
      if (Config.VIBRATION) document.getElementById("prompted").classList.add("is_shaking1");
    }
  }
};

handlerKeyUp = (e) => {
  Config.SCREEN_WIDTH = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
  ResponsiveController.setCaretToEnd();
  if (!Config.REAL_KEYBOARD){
    return;
  }
  if([37, 38, 39, 40, 9].indexOf(e.keyCode) > -1){
    e.preventDefault();
  }
  if (!Letters.allowInput) return;
  if (Config.REAL_KEYBOARD){
    document.getElementById("GRAT").classList.remove('is_shaking1');
    document.getElementById("prompted").classList.remove("is_shaking1");
    document.getElementById("caret").classList.remove("explose");
  }
  e.stopImmediatePropagation();
  if (e.key !== "Unidentified" && Config.REAL_KEYBOARD){
    HandleKeyUp(e.key);
  }
};

areaHandleChange = () => {

  Config.SCREEN_WIDTH = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
  if (document.getElementById("hiddenInputArea").value === Config.lastPred){
    document.getElementById('share').style.opacity = 1;
    Config.CAN_SHARE = true;
  }
  else {
    document.getElementById('share').style.opacity = 0.2;
    Config.CAN_SHARE = false;
  }
  ResponsiveController.setCaretToEnd();
  try{
    let x = ResponsiveController.getCoord("caret");
    ResponsiveController.changeCoord("hiddenInputArea",x);
  } catch {}
  ResponsiveController.PREV_AREA_VALUE = ResponsiveController.CURR_AREA_VALUE;
  ResponsiveController.CURR_AREA_VALUE = document.getElementById("hiddenInputArea").value;
  let lastChar = document.getElementById("hiddenInputArea").value.charAt(document.getElementById("hiddenInputArea").value.length-1);
  if ((Config.BROWSER_NAME === "Safari") && (Config.REAL_KEYBOARD) && (lastChar === '~'|| lastChar === '^' || lastChar === '¨' || lastChar === '`')) {
    document.getElementById("hiddenInputArea").value = ResponsiveController.PREV_AREA_VALUE;
    ResponsiveController.CURR_AREA_VALUE = document.getElementById("hiddenInputArea").value;
  }
  let keys_predicted = KeyPredictor.predictKey(ResponsiveController.PREV_AREA_VALUE,ResponsiveController.CURR_AREA_VALUE);
  for (let i=0; i<keys_predicted.length; i++){
    let key_predicted = keys_predicted[i];
    HandleKeyDown(key_predicted);
    if (!Config.REAL_KEYBOARD){
      HandleKeyUp(key_predicted);
      ResponsiveController.forceFocus();
    }
  }
};

function addAllWindowListeners(){
  window.onclick = handlerClick;
  window.ontouchstart = handlerTouchStart; // tactile
  window.ontouchend = handlerTouchEnd; // tactile
  window.onkeydown = handlerKeyDown;
  window.onkeyup = handlerKeyUp;
}

function removeAllWindowListeners(){

  clearTimeout(Timers.typingTimer);
  clearTimeout(Timers.displayTimer);
  clearTimeout(Timers.matrixInterval);
  clearTimeout(Timers.typingTimer);
  clearTimeout(Timers.caretTimer);

  window.onclick = null;
  window.ontouchstart = null; // tactile
  window.ontouchend = null; // tactile
  window.onkeydown = null;
  window.onkeyup = null;
}

const Handlers = {removeAllWindowListeners, addAllWindowListeners, handlerClick,handlerTouchStart,handlerTouchEnd,handlerKeyUp,handlerKeyDown, areaHandleChange};
export default Handlers;
