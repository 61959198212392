import Config from "./Config";
import Letters from "./Letters";
import Request from "./GetApi";

import Timers from "./Timers";
import RandomTextGenerator from "./RandomTextGenerator";
import ResponsiveController from "./ResponsiveController";
import Preprocess from "./Preprocess";

function HandleKeyUp(e_key){

  if (!Letters.allowInput){
    return;
  }

  if (!Config.REAL_KEYBOARD){
    setTimeout( () => {
      //document.body.removeAttribute('class');
      document.getElementById("GRAT").classList.remove('is_shaking1');
      document.getElementById("prompted").classList.remove("is_shaking1");
      document.getElementById("caret").classList.remove("explose");
    }, 100);
  }

  clearTimeout(Timers.typingTimer);
  //clearInterval(Timers.matrixInterval)
  clearTimeout(Timers.caretTimer);
  document.getElementById("caret").remove();
  document.getElementById("line"+(Letters.LastLineID)).innerHTML += '<div id="caret"></div>';
  Timers.caretTimer = setTimeout(
    () => {

      if (Letters.emptyPage){
        return;
      }
      if (Config.TUTO_OPEN){
        return;
      }
      if (document.getElementById("hiddenInputArea").value === Config.lastPred){
        return;
      }
      try {
        document.getElementById("caret").remove();
      } catch {}

      document.getElementById("line"+(Letters.LastLineID)).innerHTML += '<div id="caret" class="blink"></div>';

    },
    Config.BLINKING_PATIENCE
  );

  Timers.typingTimer = setTimeout(
    () => {
      if (Letters.emptyPage){
        return;
      }
      if (Config.TUTO_OPEN){
        return;
      }
      if (document.getElementById("hiddenInputArea").value === Config.lastPred){
        return;
      }

      Letters.allowInput = false;
      ResponsiveController.disableTextArea();
      document.getElementById("caret").remove();
      Config.CARET_REMOVED = true;
      let lastLinesPrompt = getLastLines(Letters.totalPrompt);

      let available_char_space = Config.LINE_CHAR_LIMIT - Letters.getLastLineLength(lastLinesPrompt);
      var randomLength = Math.min(Config.MATRIX_PLACE_HOLDER_LENGTH,available_char_space);


      let matrixIntervalZero = setInterval(
        () => {
          addDivForRandom(0,RandomTextGenerator.randomText(randomLength));
          let x = ResponsiveController.getCoord('letter'+Letters.LastRLetterID);
          ResponsiveController.changeCoord("hiddenInputArea",x);
        }
      ,Config.MATRIX_SPEED);

      Request.sendIP();
      Request.GetApi(lastLinesPrompt).then(
      //setTimeout(

              (res) => {

                clearTimeout(Timers.typingTimer);

                // let prediction = Letters.prediction0;
                //
                // switch (Letters.test) {
                //   default:
                //     prediction = Letters.prediction0;
                //     break;
                //   case 1:
                //     prediction = Letters.prediction1;
                //     break;
                //   case 2 :
                //     prediction = Letters.prediction2;
                //     break;
                // }
                // Letters.test = (Letters.test + 1) % 3;

                let prediction = Preprocess.processPrediction(Letters.totalPrompt,res);
                //let prediction = RandomTextGenerator.randomText(300);
                //prediction = prediction // Letters.preprocess(lastLinesPrompt,prediction);
                //console.log('prediction from API:',prediction.length,'\n',JSON.stringify(prediction));

                //console.log(">>> prediction",prediction);
                //console.log(">>> totalPrompt",Letters.totalPrompt);

                Timers.blendForce = 0;
                Config.COEFF_RD = 1;
                Timers.matrixInterval = setInterval(
                  () => {
                    clearInterval(matrixIntervalZero);
                    Config.COEFF_RD += Config.COEFF_RD;
                    randomLength = Config.COEFF_RD;
                    addDivForRandom(0, RandomTextGenerator.randomBlendText(randomLength,prediction));
                    let x = ResponsiveController.getCoord('letter'+Letters.LastRLetterID);
                    ResponsiveController.changeCoord("hiddenInputArea",x);
                    window.scroll({
                      top: Number.MAX_SAFE_INTEGER,
                      left: 0,
                      behavior: 'auto'
                    });
                  }
                ,Config.MATRIX_BLEND_SPEED);

                try {
                  document.getElementById("prompted").classList.add("blured");
                }
                catch {}

                setTimeout(
                  () => {



                    clearInterval(Timers.matrixInterval);
                    clearTimeout(Timers.typingTimer);
                    Config.promptLength = Letters.totalPrompt.length;
                    //console.log("totalPrompt",Letters.totalPrompt);
                    displayPrediction(0, prediction, randomLength);
                    if (!Config.TUTO_OPEN) Letters.allowInput = true;
                    document.getElementById("hiddenInputArea").value = Letters.totalPrompt;
                    ResponsiveController.PREV_AREA_VALUE = document.getElementById("hiddenInputArea").value;
                    ResponsiveController.CURR_AREA_VALUE = document.getElementById("hiddenInputArea").value;

                    let x = ResponsiveController.getCoord('letter'+Letters.LastLetterID);
                    ResponsiveController.changeCoord("hiddenInputArea",x);

                    if (!Config.TUTO_OPEN) ResponsiveController.enableTextArea();
                    
                    document.getElementById("line"+(Letters.LastLineID)).innerHTML += '<div id="caret"></div>';
                    if (!Config.TUTO_OPEN) ResponsiveController.forceFocus();
                    Config.CARET_REMOVED = false;



                    try {
                      document.getElementById("prompted").classList.remove("blured");
                    }
                    catch {}

                    Config.lastPred = Letters.totalPrompt;
                    document.getElementById('share').style.opacity = 1;
                    Config.CAN_SHARE = true;

                  },

                Config.WAITING_BLEND);

              },
              //Config.API_SIMU_TIME // simulation temps d'attente API
            );
    },
    Config.NO_INPUT_PATIENCE
  );


}

function deleteNonUsedDiv(nline,nletter){
  let k_line = 1;
  while(true){
    try {
      document.getElementById("line"+(nline+k_line)).remove(); // to check if it is null
    }
    catch {
      break;
    }
    k_line++;
  }

  let k_letter = 1;
  while(true){
    try {
      document.getElementById("letter"+(nletter+k_letter)).remove(); // to check if it is null
    }
    catch {
      break;
    }
    k_letter++;
  }
}


function addDivForRandom(repeatSize, prediction){ // OK

  Letters.LastRLineID = Letters.LastLineID;
  Letters.LastRLetterID = Letters.LastLetterID;
  deleteNonUsedDiv(Letters.LastRLineID,Letters.LastRLetterID);

  for (let i = repeatSize; i<prediction.length; i++){

    if (prediction.charAt(i) === '\n'){

      // Try line id exists
      try {
        let tmp = document.getElementById("line"+(Letters.LastRLineID+1)).innerHTML; // to check if it is null
        let f = () => {}; f(tmp);
        Letters.LastRLineID++;
      } catch {

        document.getElementById("prompted").innerHTML += '<div class="line" id="line'+(++Letters.LastRLineID)+'"></div>';

      } finally {

        // Try letter id exists
        try {
          document.getElementById("letter"+(Letters.LastRLetterID+1)).innerHTML = Config.NEW_LINE_CHAR;
          Letters.LastRLetterID++;
        } catch {
          document.getElementById("line"+Letters.LastRLineID).innerHTML += "<div class='letter red' id=letter"+(++Letters.LastRLetterID)+">"+Config.NEW_LINE_CHAR+"</div>";
        }

        //Letters.totalPrompt += '\n';
      }


    }

    else {


      let currChar = prediction.charAt(i) === ' ' ? "&nbsp;" : prediction.charAt(i);
      try {

        document.getElementById("letter"+(Letters.LastRLetterID+1)).innerHTML = currChar;
        Letters.LastRLetterID++;

      } catch {

        document.getElementById("line"+Letters.LastRLineID).innerHTML += "<div class='letter red' id=letter"+(++Letters.LastRLetterID)+">"+currChar+"</div>";

      }
      //Letters.totalPrompt += prediction.charAt(i);


      if (ResponsiveController.isOffScreen("letter"+(Letters.LastRLetterID))){
        //return;


        //let decalage = 0;
        //let prev = (Letters.totalPrompt.slice(0, Letters.LastRLetterID-decalage) + "\n" + Letters.totalPrompt.slice(Letters.LastLetterID-decalage))
        //ResponsiveController.PREV_AREA_VALUE = prev;
        ResponsiveController.newLineFromPrevious(false);
        //ResponsiveController.CURR_AREA_VALUE = prev;

      }

    }

  }

  deleteNonUsedDiv(Letters.LastRLineID,Letters.LastRLetterID);

}

function displayPrediction(repeatSize, prediction, randomLength){ // OK

  deleteNonUsedDiv(Letters.LastLineID,Letters.LastLetterID);

  for (let i = repeatSize; i<prediction.length; i++){

    if (prediction.charAt(i) === '\n'){

      // Try line id exists
      try {
        let tmp = document.getElementById("line"+(Letters.LastLineID+1)).innerHTML; // to check if it is null
        let f = () => {}; f(tmp);
        Letters.LastLineID++;
      } catch {

        document.getElementById("prompted").innerHTML += '<div class="line" id="line'+(++Letters.LastLineID)+'"></div>';

      } finally {

        // Try letter id exists
        try {
          document.getElementById("letter"+(Letters.LastLetterID+1)).innerHTML = Config.NEW_LINE_CHAR;
          Letters.LastLetterID++;
        } catch {
          document.getElementById("line"+Letters.LastLineID).innerHTML += "<div class='letter red' id=letter"+(++Letters.LastLetterID)+">"+Config.NEW_LINE_CHAR+"</div>";
        }

        Letters.totalPrompt += '\n';
      }


    }

    else {


      let currChar = prediction.charAt(i) === ' ' ? "&nbsp;" : prediction.charAt(i);
      try {

        document.getElementById("letter"+(Letters.LastLetterID+1)).innerHTML = currChar;
        Letters.LastLetterID++;

      } catch {

        document.getElementById("line"+Letters.LastLineID).innerHTML += "<div class='letter red' id=letter"+(++Letters.LastLetterID)+">"+currChar+"</div>";

      }
      Letters.totalPrompt += prediction.charAt(i);


      if (ResponsiveController.isOffScreen("letter"+(Letters.LastLetterID))){

        let decalage = 0;
        let prev = (Letters.totalPrompt.slice(0, Letters.LastLetterID-decalage) + "\n" + Letters.totalPrompt.slice(Letters.LastLetterID-decalage))
        ResponsiveController.PREV_AREA_VALUE = prev;
        ResponsiveController.newLineFromPrevious(true);
        ResponsiveController.CURR_AREA_VALUE = prev;

      }

    }

  }

  deleteNonUsedDiv(Letters.LastLineID,Letters.LastLetterID);

}


function getLastLines(word){
  let tab = word.split('\n');
  let l = Math.min(3,tab.length);
  let tab2 = tab.slice(-l);
  let res = tab2.join('\n');
  let prefix = l === 3 ? '\n' : ''
  res = prefix+res;
  return res;
}

export default HandleKeyUp;
