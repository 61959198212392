import Header from "./Header";
import APIContainer from "./APIContainer";
import Menu from "./Menu";

function Home() {

  return (
    <>
      <Menu />
      <main id="main">
        <Header />
        <APIContainer />
      </main>
    </>
  );
}

export default Home;
