import Letters from "./Letters";
import Config from "./Config"
import {publicIpv4} from 'public-ip';

//creating function to load ip address from the API

const getIP = async () => {
  return (await publicIpv4().then(
    ip => {return ip;}
    )
  );
}


async function sendIP(){
  getIP().then(ip =>
    {
      //console.log('++ IP ++',ip);
      //console.log(ip, process.env.REACT_APP_ZTFUWUBWAQ);
      fetch(process.env.REACT_APP_ZTFUWUBWAQ+ip,
          { headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          }
        }).then( (result) => {
          //console.log("++ RES IP => RES ++",result);
          return result.message;
        }).catch( err => {
          //console.log('-- RES IP => X --',err);
        });
    }
  );

}

async function GetApi(entry) {
  entry = encode(entry);
  let y = f();
  try {
    //console.log("++ LANG ++",Config.LANGUAGE);
    let response = await fetch(process.env.REACT_APP_ZTFLWUBWAQ+`${Config.LANGUAGE}/${y}/${entry}`,
        { headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
      }).then((res) => {
        if (res.ok){
         //console.log("++ RES.OK => RES.JSON ++");
         return res.json();
       }
        else{
          //console.log("-- RES.OK => X --");
          //console.log(res);
          throw new Error('-- ERROR RES.OK --');
        }
      })
      .then((result) => {
        //console.log("++ RES.JSON => RES.MESSAGE ++",result.message);
        return result.message;
      }).catch( err => {
        //console.log("-- RES.JSON => X --", err);
        return Config.DEFAUT_PRED(Config.LANGUAGE);
      });
    return response;
  } catch {
    //console.log("-- TRY => CATCH --");
    return Config.DEFAUT_PRED(Config.LANGUAGE);
  }
}

function f(){
  let x1 = "[1,2,3,4]";
  let x2 = "[2,3,4]";
  let x3 = "[2,3]";
  let x4 = "[3,4]";

  if (Letters.LastLineID === 0){
    return x1;
  }
  else if (Letters.LastLineID === 1){
    let choice = Math.floor(Math.random() * 3);
    if (choice===0){
      return x1;
    } else if(choice === 1){
      return x2;
    } else {
      return x3;
    }
  }
  else {
    let choice = Math.floor(Math.random() * 3);
    if (choice===0){
      return x2;
    } else if(choice === 1){
      return x4;
    } else {
      return x3;
    }
  }
}

function encode(entry){

  let res = '';
  let isEmpty = true;

  for (let i=0; i<entry.length; i++){

    if (entry.charAt(i) === '/'){
      isEmpty = false;
      res += '[SLASH]';
    } else if (entry.charAt(i) === '?') {
      isEmpty = false;
      res += '[INT]';
    } else if (entry.charAt(i) === '#') {
      isEmpty = false;
      res += '[HASH]';
    } else {

      if (entry.charAt(i) !== ' ' && entry.charAt(i) !== '\n'){
        isEmpty = false;
      }
      res += entry.charAt(i);
    }

  }


  if (isEmpty){
    return '∂';
  }
  return res;
}

const Request = {GetApi, sendIP};

export default Request;
