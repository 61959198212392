var REAL_KEYBOARD = true;
var currentFontSize = 10.0;

const BROWSER_NAME = (function (agent) {        switch (true) {
  case agent.indexOf("edge") > -1: return "MS Edge";
  case agent.indexOf("edg/") > -1: return "Edge ( chromium based)";
  case agent.indexOf("opr") > -1 && !!window.opr: return "Opera";
  case agent.indexOf("chrome") > -1 && !!window.chrome: return "Chrome";
  case agent.indexOf("trident") > -1: return "MS IE";
  case agent.indexOf("firefox") > -1: return "Mozilla Firefox";
  case agent.indexOf("safari") > -1: return "Safari";
  default: return "other";
}
})(window.navigator.userAgent.toLowerCase());

var SCREEN_WIDTH = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
//const SCREEN_HEIGHT = (window.innerHeight > 0) ? window.innerHeight : window.screen.height;
const LINE_CHAR_LIMIT = 150*5 ; //SCREEN_WIDTH > 400 ? 48+5 : 32;

const MATRIX_PLACE_HOLDER_LENGTH = 5;

// milliseconds
const NO_INPUT_PATIENCE = 5000;
const MATRIX_SPEED = 50;
const MATRIX_BLEND_SPEED = 50;
const WAITING_BLEND = 750;
const BLINKING_PATIENCE = NO_INPUT_PATIENCE-2000; // 2000
const API_SIMU_TIME = 1000;

const NEW_LINE_CHAR = '<br>';
const COEFF_RD = 1.3;

const AT = "@app_grat";
var LANGUAGE = 'en';
const SHARE_INTRO_FR = "\n\n";
const SHARE_OUTRO_FR = "\n\n";
var CAN_SHARE = false;

var FIRST_TIME_TUTO = true;
var TUTO_OPEN = true;
var CARET_REMOVED = false;

var lastPred = null;
var promptLength = 0;

var MUTED = false;
var VIBRATION = true;


function CREATED_BY(lg){
  if (lg === 'fr') return "Créé par";
  if (lg === 'en') return "Created by";
  if (lg === 'es') return "Creado por";
}

function COMPRIS(lg){
  if (lg === 'fr') return "J'accepte.";
  if (lg === 'en') return "I accept.";
  if (lg === 'es') return "Acepto.";
}

function COMPRIS_TUTO(lg){
  if (lg === 'fr') return "Quitter le tutoriel";
  if (lg === 'en') return "Exit the tutorial";
  if (lg === 'es') return "Salir del tutorial";
}


function TUTO_TITLE(lg){
  if (lg === 'fr') return "TUTORIEL";
  if (lg === 'en') return "TUTORIAL";
  if (lg === 'es') return "TUTORIAL";
}

function TUTO_LANG(lg){
  if (lg === 'fr') return "Vous pouvez également changer la langue dans laquelle vous souhaitez écrire.";
  if (lg === 'en') return "You can also change the language in which you want to write.";
  if (lg === 'es') return "También puede cambiar el idioma en el que desea escribir.";
}

function TUTO_TYPE(lg){
  if (lg === 'fr') return "Écrivez tant que vous avez de l'inspiration.";
  if (lg === 'en') return "Write while you are inspired.";
  if (lg === 'es') return "Escriba mientras esté inspirado.";
}

function TUTO_EFFECT(lg){
  if (lg === 'fr') return "Pour modifier les effets sonores et visuels, cliquer sur";
  if (lg === 'en') return "To change the sound and visual effects, click on";
  if (lg === 'es') return "Para cambiar los efectos sonoros y visuales, haga clic en";
}

function TUTO_ERASE(lg){
  if (lg === 'fr') return "Pour tout effacer, cliquer sur ";
  if (lg === 'en') return "To erase everything, click on";
  if (lg === 'es') return "Para borrarlo todo, haga clic en";
}

function TUTO_WAIT_1(lg){
  if (lg === 'fr') return "Pour que l'IA génère du texte, arrêtez d'écrire, et patientez quelques secondes.";
  if (lg === 'en') return "To have the AI generate text, stop writing, and wait a few seconds.";
  if (lg === 'es') return "Para que la IA genere el texto, deje de escribir y espere unos segundos.";
}

function TUTO_WAIT_2(lg){
  if (lg === 'fr') return "Le curseur va changer de couleur.";
  if (lg === 'en') return "The cursor will change color.";
  if (lg === 'es') return "El cursor cambiará de color.";
}

function TUTO_WAIT_3(lg){
  if (lg === 'fr') return "Attendez. L'IA se prépare.";
  if (lg === 'en') return "Hold on. The AI is getting ready.";
  if (lg === 'es') return "Espere un momento. La IA se está preparando.";
}

function TUTO_GENERATION(lg){
  if (lg === 'fr') return "L'IA a fini. C'est de nouveau à votre tour, ou bien...";
  if (lg === 'en') return "The AI is done. It's your turn again, or else...";
  if (lg === 'es') return "La IA ha terminado. Es su turno de nuevo, o si no...";
}

function TUTO_SCREENSHOT(lg){
  if (lg === 'fr') return "... vous pouvez afficher votre carte GRAT en cliquant sur";
  if (lg === 'en') return "... you can view your GRAT card by clicking on";
  if (lg === 'es') return "... puede visualizar su carta GRAT haciendo clic en";
}

function TUTO_EFFECT_BUTTON_0(lg){
  if (lg === 'fr') return "4 états possibles :";
  if (lg === 'en') return "4 possible states :";
  if (lg === 'es') return "4 estados posibles:";
}

function TUTO_EFFECT_BUTTON_1(lg){
  if (lg === 'fr') return "Son, tremblements";
  if (lg === 'en') return "Sound, screen shake";
  if (lg === 'es') return "Sonido, vibraciones";
}

function TUTO_EFFECT_BUTTON_2(lg){
  if (lg === 'fr') return "Son, pas de tremblements";
  if (lg === 'en') return "Sound, no screen shake";
  if (lg === 'es') return "Sonido, sin vibraciones";
}

function TUTO_EFFECT_BUTTON_3(lg){
  if (lg === 'fr') return "Pas de son, tremblements";
  if (lg === 'en') return "No sound, screen shake";
  if (lg === 'es') return "Sin sonido, vibraciones";
}

function TUTO_EFFECT_BUTTON_4(lg){
  if (lg === 'fr') return "Pas de son, pas de tremblements";
  if (lg === 'en') return "No sound, no screen shake";
  if (lg === 'es') return "Sin sonido, sin vibraciones";
}

function TUTO_TUTO(lg){
  if (lg === 'fr') return "Vous pouvez consulter à tout moment ce tutoriel en appuyant sur";
  if (lg === 'en') return "You can access this tutorial at any time by pressing";
  if (lg === 'es') return "Puede acceder a este tutorial en cualquier momento pulsando";
}

function DISCLAIMER_TITLE(lg){
  if (lg === 'fr') return "L'IA qui écrit des vers";
  if (lg === 'en') return "The AI that writes verses";
  if (lg === 'es') return "La IA que escribe versos";
}

function DISCLAIMER_TITLE_2(lg){
  if (lg === 'fr') return "AVERTISSEMENT";
  if (lg === 'en') return "DISCLAIMER";
  if (lg === 'es') return "ADVERTENCIA";
}

function DISCLAIMER_CONTENT_1(lg){
  if (lg === 'fr') return "Ce site web fait intervenir une Intelligence Artificielle (IA) spécialisée dans l'écriture de textes versifiés (poèmes, couplets de rap et chansons). Elle a été entraînée à partir de poèmes et couplets trouvables sur Internet. Cette IA peut donc produire du contenu explicite voire choquant."
  if (lg === 'en') return "This website uses an Artificial Intelligence (AI) specialized in writing versified texts (poems, rap verses and songs). It has been trained on poems and verses found on the Internet. This AI can therefore produce explicit and even shocking content.";
  if (lg === 'es') return "Este website utiliza una Inteligencia Artificial (IA) especializada en la escritura de textos versificados (poemas, versos de rap y canciones). Se ha entrenado con poemas y versos encontrados en Internet. Por lo tanto, esta IA puede producir contenidos explícitos o incluso chocantes.";
}

function DISCLAIMER_CONTENT_2(lg){
  if (lg === 'fr') return "L'équipe de développement ne revendique aucun droit sur les textes produits à l'aide de cet outil. Les membres de l'équipe n'approuvent, ne tolèrent et n'encouragent en aucune façon les opinions qui pourraient figurer dans les textes et les comportements qui pourraient y être décrits. Vous êtes libres d'utiliser ces textes et êtes responsables de leur utilisation, en particulier si leur contenu violait des lois, causait un préjudice à une personne, diffusait des informations erronées ou susceptibles de nuire, notamment à des minorités.";
  if (lg === 'en') return "The development team does not claim any rights to the texts produced with this tool. The team members do not approve, condone or encourage in any way the opinions that may appear in the texts and the behaviors that may be described in them. You are free to use these texts and are responsible for their use, especially if their content violates any laws, causes harm to any person, disseminates false information or is likely to harm minorities.";
  if (lg === 'es') return "El equipo de desarrollo no reclama ningún derecho sobre los textos producidos con esta herramienta. Los miembros del equipo no aprueban, condonan ni fomentan en modo alguno las opiniones que puedan aparecer en los textos ni los comportamientos que puedan describirse en ellos. Usted es libre de utilizar los textos y es responsable de su uso, en particular si su contenido viola alguna ley, causa daño a alguna persona, difunde información falsa o puede perjudicar, en particular, a las minorías.";
}

function DISCLAIMER_CONTENT_3(lg){
  if (lg === 'fr') return "En poursuivant sur ce site, vous acceptez les conditions énoncées précédemment.";
  if (lg === 'en') return "By continuing on this website, you agree to the above terms and conditions.";
  if (lg === 'es') return "Al continuar en este website, usted acepta las condiciones anteriores.";
}




function DEFAUT_PRED(lg){
  if (lg === 'fr') return " J'ai pas d'inspiration...";
  if (lg === 'en') return " I don't have any inspiration...";
  if (lg === 'es') return " No tengo ninguna inspiración...";
}

function Anonyme(lg){
  if (lg === 'fr') return "Anonyme";
  if (lg === 'en') return "Anonymous";
  if (lg === 'es') return "Anónimo";
}
function Share(lg){
  if (lg === 'fr') return "Partager";
  if (lg === 'en') return "Share";
  if (lg === 'es') return "Compartir";
}

function ShareIntro(lg){
  if (lg === 'fr') return SHARE_INTRO_FR;
  if (lg === 'en') return SHARE_INTRO_FR;
  if (lg === 'es') return SHARE_INTRO_FR;
}

function ShareOutro(lg){
  if (lg === 'fr') return SHARE_OUTRO_FR;
  if (lg === 'en') return SHARE_OUTRO_FR;
  if (lg === 'es') return SHARE_OUTRO_FR;
}

function Clear(lg){
  if (lg === 'fr') return "Effacer";
  if (lg === 'en') return "Clear";
  if (lg === 'es') return "Borrar";
}

const Config = {CARET_REMOVED, TUTO_OPEN, FIRST_TIME_TUTO, TUTO_TUTO, CREATED_BY, COMPRIS_TUTO, TUTO_EFFECT_BUTTON_0, TUTO_EFFECT_BUTTON_1, TUTO_EFFECT_BUTTON_2, TUTO_EFFECT_BUTTON_3, TUTO_EFFECT_BUTTON_4, TUTO_TITLE, TUTO_LANG, TUTO_TYPE, TUTO_EFFECT, TUTO_ERASE, TUTO_WAIT_1, TUTO_WAIT_2, TUTO_WAIT_3, TUTO_GENERATION, TUTO_SCREENSHOT, DISCLAIMER_TITLE_2, VIBRATION, MUTED, COMPRIS, DISCLAIMER_TITLE, DISCLAIMER_CONTENT_1, DISCLAIMER_CONTENT_2, DISCLAIMER_CONTENT_3, DEFAUT_PRED, Anonyme, promptLength, lastPred, CAN_SHARE, AT, ShareIntro, ShareOutro, Clear, Share,SHARE_INTRO_FR, SHARE_OUTRO_FR, LANGUAGE, COEFF_RD, currentFontSize, SCREEN_WIDTH, REAL_KEYBOARD, NEW_LINE_CHAR, API_SIMU_TIME, LINE_CHAR_LIMIT, BLINKING_PATIENCE, NO_INPUT_PATIENCE,MATRIX_PLACE_HOLDER_LENGTH,MATRIX_SPEED,MATRIX_BLEND_SPEED,
WAITING_BLEND, BROWSER_NAME};

export default Config;
