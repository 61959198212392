

import Config from "./Scripts/Config";
import Letters from "./Scripts/Letters";
import Handlers from "./Scripts/Handlers";
//import ResponsiveController from "./Scripts/ResponsiveController";

//import html2canvas from 'html2canvas';

function Menu() {


    let showTuto = () => {

        //Letters.allowInput = false;
        //let tmp = Config.REAL_KEYBOARD;
        //Config.REAL_KEYBOARD = false;
        //document.getElementById("hiddenInputArea").value = "";
        //Handlers.areaHandleChange();
        //Config.REAL_KEYBOARD = tmp;
        //Letters.allowInput = false;
        //document.getElementById("msa-popup1").style.visibility = "hidden";
        //document.getElementById("msa-popup1").style.opacity = "0";
        Config.TUTO_OPEN = true;
        Letters.allowInput = false;
        try{
          document.getElementById("caret").classList.remove("blink");
        }
        catch{}
        document.getElementById("msa-popup2").style.visibility = "visible";
        document.getElementById("msa-popup2").style.opacity = "1";
        //console.log(Config.LANGUAGE);
        //Letters.allowInput = false;
        //ResponsiveController.disableTextArea();
        //document.getElementById("caret").remove();
        //let tmp = Config.REAL_KEYBOARD;
        //Config.REAL_KEYBOARD = false;
        //document.getElementById("hiddenInputArea").value = "";
        //Handlers.areaHandleChange();
        //document.getElementById("msa-popup1").style.visibility = "hidden";
        //document.getElementById("msa-popup1").style.opacity = "0";
        //document.getElementById("msa-popup2").style.visibility = "visible";
        //document.getElementById("msa-popup2").style.opacity = "1";


        //Config.REAL_KEYBOARD = tmp;
        //Letters.allowInput = true;
        //ResponsiveController.addPrefixString(0);
        //ResponsiveController.enableTextArea();
        //ResponsiveController.forceFocus();
    }

    // Init language
    var userLang = navigator.language || navigator.userLanguage;
    if (userLang.charAt(0) === 'e' && userLang.charAt(1) === 'n'){
        Config.LANGUAGE = 'en';
    }
    else if (userLang.charAt(0) === 'f' && userLang.charAt(1) === 'r'){
        Config.LANGUAGE = 'fr';
    }
    else if (userLang.charAt(0) === 'e' && userLang.charAt(1) === 's'){
        Config.LANGUAGE = 'es';
    }
    else {
        Config.LANGUAGE = 'en';
    }
    Letters.allowInput = false;
    //const [lang, setLang] = Config.LANGUAGE;

    function play() {
      if (Config.MUTED) return;
      var audio = document.getElementById("audio");
      audio.currentTime = 0.45;
      audio.play();
    }

    function isFR(){
      if (Config.LANGUAGE === 'fr') return "menu-button-text menu-button-selected";
      return "menu-button-text";
    }

    function isEN(){
      if (Config.LANGUAGE === 'en') return "menu-button-text menu-button-selected";
      return "menu-button-text";
    }

    function isES(){
      if (Config.LANGUAGE === 'es') return "menu-button-text menu-button-selected";
      return "menu-button-text";
    }

    const changeVibration = () => {

      if (Config.VIBRATION && !Config.MUTED){
        Config.VIBRATION = false;
        Config.MUTED = false;
        document.getElementById('vibration').src = require("../res/unvibr_unmute.png");
        return;
      }

      if (!Config.VIBRATION && !Config.MUTED){
        Config.VIBRATION = false;
        Config.MUTED = true;
        document.getElementById('vibration').src = require("../res/unvibr_mute.png");
        return;
      }

      if (!Config.VIBRATION && Config.MUTED){
        Config.VIBRATION = true;
        Config.MUTED = true;
        document.getElementById('vibration').src = require("../res/vibr_mute.png");
        return;
      }

      if (Config.VIBRATION && Config.MUTED){
        Config.VIBRATION = true;
        Config.MUTED = false;
        document.getElementById('vibration').src = require("../res/vibr_unmute.png");
        return;
      }

      return;
    };

    const setLanguage = (langage) => {
      Config.LANGUAGE = langage;
      document.getElementById('fr').classList.remove("menu-button-selected");
      document.getElementById('en').classList.remove("menu-button-selected");
      document.getElementById('es').classList.remove("menu-button-selected");
      document.getElementById(Config.LANGUAGE).classList.add("menu-button-selected");

      //document.getElementById('language').innerHTML = Config.LANGUAGE.toUpperCase();
      //document.getElementById('clear').innerHTML = Config.Clear(Config.LANGUAGE);
      //document.getElementById('languageDropdown').appendChild('<div className="button">OO</div>');


      // document.getElementById('languageDropdown').innerHTML  = "
      //
      // <div className="button" onClick={() => setLanguage('fr')}><div className='btnText'>FR</div></div>
      // <div className="button" onClick={() => setLanguage('en')}><div className='btnText'>EN</div></div>
      // <div className="button" onClick={() => setLanguage('es')}><div className='btnText'>ES</div></div>
      // ";
      return;
    };

    const clear = () => {
      if (!Letters.allowInput) return;
      play();
      let realKeyboardTMP = Config.REAL_KEYBOARD;
      Config.REAL_KEYBOARD = false;
      document.getElementById("hiddenInputArea").value = "";
      //console.log(document.getElementById("hiddenInputArea").value);
      Handlers.areaHandleChange();
      Config.REAL_KEYBOARD = realKeyboardTMP;
    };

    // const shareWA = (ft) => {
    //
    //   let res = encodeURI(ft);
    //   res = res.replace("#","%23");
    //   res = "whatsapp://send?text="+Config.SHARE_INTRO_FR+"%0a%0a```  **********%0a"+res+"%0a  **********```%0a%0a"+Config.SHARE_OUTRO_FR;
    //   return res;
    // };

    function lettersToText(){
      // let res = '';
      // for (let i=0; i<Letters.LastLetterID+1; i++){
      //   if (document.getElementById("letter"+i).innerHTML === '&nbsp;') res += ' ';
      //   else if (document.getElementById("letter"+i).innerHTML === Config.NEW_LINE_CHAR ) res += '\n';
      //   else res += document.getElementById("letter"+i).innerHTML ;
      // }
      return Letters.totalPrompt;
    }

    const saveImg = (e) => {

          if (!Config.CAN_SHARE) return;
        // e.preventDefault()
        // if (Letters.totalPrompt.length) {
        //     let res = Letters.totalPrompt+"\n\n Texte généré avec https://grat.app";
        //     navigator.clipboard.writeText(res).then(() => {
        //         //alert("Texte copié !");
        //     })
        // } else {
        //     //alert("Le texte est vide.");
        // }

          //let txt = "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@\n";
          //txt += "Hello World ! C'était mon dernier souhait\nTu sais, j'suis plus fort\nque toi.\nTu m'as dis des trucs de fou,\nOn t'as dit laisse tomber c'est la police\nOk ok j'ai la dalle.";
          //txt += txt ;
          //txt += txt ;
          //txt += txt ;
          let txt = lettersToText();
          let lines = txt.split('\n');
          //console.log(lines);
          for (let i=1; i<lines.length; i++){
            lines[i] = '\n' + lines[i];
          }
          //console.log(JSON.stringify(Letters.totalPrompt));


          var canvas = document.createElement('canvas');

          // Init
          //let cote = 1500;
          let cote = 2000;
          if (Config.SCREEN_WIDTH > 420 || lines.length > 9) cote = 3200;
          if (Config.SCREEN_WIDTH >= 3000 || lines.length > 26) cote = 4400;
          let margin_cote = 0;
          canvas.height= cote;
          canvas.width = cote;
          var ctx = canvas.getContext('2d');

          // Carré 1
          ctx.fillStyle = "#b88e58";
          ctx.fillRect(0, 0, cote, cote);

          // Carré 2
          ctx.fillStyle = "white";
          margin_cote = 25;
          ctx.fillRect(margin_cote, margin_cote, cote-margin_cote*2, cote-margin_cote*2);

          // Carré 2
          ctx.fillStyle = "#6fa8dc";
          margin_cote = 47;
          ctx.fillRect(margin_cote, margin_cote, cote-margin_cote*2, cote-margin_cote*2);

          // Carré 2
          ctx.fillStyle = "#191a1e";
          margin_cote = 50;
          ctx.fillRect(margin_cote, margin_cote, cote-margin_cote*2, cote-margin_cote*2);

          // Text
          let f = new FontFace("test", "url(https://fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vq_QOW4Ep0.woff2)");
          let f2 = new FontFace("test2", "url(https://fonts.gstatic.com/s/righteous/v13/1cXxaUPXBpj2rGoU7C9WhnGFucE.woff2)");
          f2.load().then( () => {
          f.load().then(() => {
            // Ready to use the font in a canvas context



            let coeff = 2;
            let lineheight = 30*coeff*1.5;
            let letter_width = 17*coeff;

            ctx.font = (200)+"px Roboto Mono";
            ctx.textAlign = "left";
            ctx.shadowColor="#6fa8dc";
            ctx.shadowBlur=0;
            ctx.lineWidth=0;
            ctx.fillStyle = "#b88e58";
            ctx.fillText("“", canvas.width/3, canvas.height/2 + ((-1-lines.length/2)*lineheight) );



            ctx.font = "bold "+(30*coeff)+"px Roboto Mono";
            //if (Config.SCREEN_WIDTH < 400) ctx.font = "bold "+(50)+"px Roboto Mono";
            ctx.textAlign = "center";
            ctx.shadowColor="#6fa8dc";
            ctx.strokeStyle="#6fa8dc"
            ctx.shadowBlur=0;
            ctx.lineWidth=1;

            let i = 0;
            let j = 0;
            let k = 0;

            // for (i = 0; i<lines.length; i++){
            //   for (j = 0; j<lines[i].length; j++){
            //     console.log(k, j, lines[i].length, lines[i].charAt(j));
            //     if ( document.getElementById("letter"+k).className !== 'letter red' ){
            //       ctx.strokeText(lines[i].charAt(j), (canvas.width/2 - lines[i].length/2*letter_width + j*letter_width) + letter_width/2, canvas.height/2 + ((i-lines.length/2)*lineheight) );
            //     }
            //     k++;
            //
            //   }
            // }


            k = 0;
            ctx.shadowBlur=0;
            ctx.fillStyle = "white";
            for (i = 0; i<lines.length; i++){
              for (j = 0; j<lines[i].length; j++){

                if ( document.getElementById("letter"+k).className === 'letter red' ){
                  //console.log(k,Letters.totalPrompt.charAt(k), lines[i].charAt(j));
                  ctx.fillStyle = "#b88e58";
                } else {
                  ctx.fillStyle = "white";
                  ctx.strokeText(lines[i].charAt(j), (canvas.width/2 - lines[i].length/2*letter_width + j*letter_width) + letter_width/2, canvas.height/2 + ((i-lines.length/2)*lineheight) );
                }

                ctx.fillText(lines[i].charAt(j), (canvas.width/2 - lines[i].length/2*letter_width + j*letter_width) + letter_width/2, canvas.height/2 + ((i-lines.length/2)*lineheight) );

                k++;

              }
            }


            ctx.textAlign = "right";
            //ctx.font = "italic "+(100)+"px Righteous";
            ctx.font = ""+(70)+"px Roboto Mono";

            //ctx.fontStyle = "";
            // ctx.textAlign = "center";
            // ctx.shadowColor="#6fa8dc";
            // ctx.shadowBlur=0;
            // ctx.lineWidth=0;
            // ctx.strokeText("GRAT.APP", 2*canvas.width/3, canvas.height/2 + ((i+3-lines.length/2)*lineheight) );
            //
            ctx.shadowBlur=0;
            ctx.fillStyle = "white" //"#b88e58";
            ctx.fillText(Config.Anonyme(Config.LANGUAGE)+" ft", 4*canvas.width/5 , canvas.height/2 + ((i+2-lines.length/2)*lineheight) );
            ctx.strokeText(Config.Anonyme(Config.LANGUAGE)+" ft", 4*canvas.width/5 , canvas.height/2 + ((i+2-lines.length/2)*lineheight) );

            // ctx.font = ""+(50)+"px Roboto Mono";
            // ctx.shadowBlur=0;
            // ctx.fillStyle = "white" //"#b88e58";
            // ctx.fillText("X", 2*canvas.width/3, canvas.height/2 + ((i+3-lines.length/2)*lineheight-10) );

            ctx.font = ""+(100)+"px Righteous";

            ctx.shadowBlur=0;
            ctx.fillStyle = "#b88e58" //"#b88e58";
            ctx.fillText("GRAT.app", 4*canvas.width/5, canvas.height/2 + ((i+3-lines.length/2)*lineheight+10) );

            // TIKTOK
            var img = new Image();
            img.src = require("../res/twitter.png");
            //img.height = 100;
            //img.width = 100;
            let pseudo_x = 0;
            img.onload = function(){
              ctx.drawImage(img,margin_cote*2,cote-margin_cote*2-img.height); // Or at whatever offset you like
              pseudo_x = img.height;

              // ctx.font = (60)+"px Merriweather Sans";
              // ctx.textAlign = "left";
              // ctx.shadowColor="#6fa8dc";
              // ctx.shadowBlur=1;
              // ctx.lineWidth=0;
              // ctx.fillText(Config.TIKTOK,margin_cote*2+pseudo_x, cote-margin_cote-pseudo_x/2);

              // TWITTER
              img = new Image();
              img.src = require("../res/twitter.png");
              //pseudo_x = 0;
              img.onload = function(){

                //ctx.drawImage(img,margin_cote*2 + pseudo_x,cote-margin_cote*2-img.height); // Or at whatever offset you like
                //pseudo_x = img.height;

                // ctx.font = (60)+"px Merriweather Sans";
                // ctx.textAlign = "left";
                // ctx.shadowColor="#6fa8dc";
                // ctx.shadowBlur=1;
                // ctx.lineWidth=0;
                //ctx.fillText(Config.TIKTOK,margin_cote*2+pseudo_x, cote-margin_cote-pseudo_x/2);

                // INSTAGRAM
                img = new Image();
                img.src = require("../res/instagram.png");
                //pseudo_x = 0;
                img.onload = function(){

                  //ctx.drawImage(img,margin_cote*2 + 2*pseudo_x,cote-margin_cote*2-img.height); // Or at whatever offset you like
                  //pseudo_x = img.height;

                  // adresse
                  ctx.font = (45)+"px Roboto Mono";
                  ctx.textAlign = "left";
                  ctx.shadowColor="#6fa8dc";
                  ctx.shadowBlur=1;
                  ctx.lineWidth=0;
                  ctx.fillText(Config.AT,margin_cote*2 + pseudo_x + 10, cote-margin_cote*2 - pseudo_x/3);

                  // console.log("ppppp");
                  // console.log(canvas);
                  // console.log("ppppp");
                  // console.log(canvas.toDataURL("image/png"));
                  // console.log("ppppp");
                  //console.log(canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
                  // console.log("ppppp");

                  // let data = canvas.toDataURL("image/png");
                  // let w = window.open('about:blank');
                  // let image = new Image();
                  // image.src = data;
                  // setTimeout(function(){
                  //   w.document.write(image.outerHTML);
                  // }, 0);

                  const base64ImageData = canvas.toDataURL("image/png");
                  const contentType = 'image/png';

                  const byteCharacters = atob(base64ImageData.substr(`data:${contentType};base64,`.length));
                  const byteArrays = [];

                  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                      const slice = byteCharacters.slice(offset, offset + 1024);

                      const byteNumbers = new Array(slice.length);
                      for (let i = 0; i < slice.length; i++) {
                          byteNumbers[i] = slice.charCodeAt(i);
                      }

                      const byteArray = new Uint8Array(byteNumbers);

                      byteArrays.push(byteArray);
                  }

                  const link = document.createElement('a');

                  const blob = new Blob(byteArrays, {type: contentType});
                  const url = window.URL.createObjectURL(blob);
                  if (
                    // isFirefox
                    true ||
                    (window.navigator.userAgent.indexOf('Firefox') !== -1 &&
                    window.navigator.userAgent.indexOf('Chrome') === -1)
                  ) {
                    //link.target = '_blank';
                  }
                  link.href = url;
                  document.body.appendChild(link);
                  link.click();
                  link.remove();


                  //window.open(blobUrl, '_blank');

                  //var link = document.createElement('a');
                  //link.id = 'dl_link';

                  //link.setAttribute('download', 'GRAT.jpg');
                  //link.setAttribute('href', canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
                  //link.click();
                  //console.log(link);
                  //link.remove();
                }

              }


              //document.body.appendChild(canvas);




            };



            // ============================================================== //




          });});


          //ctx.strokeStyle = "white";
          //ctx.shadowBlur = 20;
          //ctx.shadowColor = "white";
          //ctx.strokeRect(0, 0, cote, cote);

          // Download


          //ctx.font = "25px serif";
          //ctx.textBaseline = "hanging";
          //ctx.strokeText("Hello world", 0, 100);


          return canvas;
        };



      //<li><div id="save" className="button" onClick={() => saveImg()} > <img className="icon" src={require("../res/copy.png")}  alt="Save"/> </div> </li>



      // <li className="dropdown">
      //   <div className="dropbtn" id="share">{Config.Share(Config.LANGUAGE)}</div>
      //   <div className="dropdown-content">
      //     <div><a className="button" onClick={() => setToShare(shareWA(Letters.totalPrompt))} href={toShare} data-action="share/whatsapp/share"> WhatsApp </a> </div>
      //   </div>
      // </li>


    // }

    //         <li><div className="button btn" data-clipboard-text="1" onClick={(e) => copyText(e) }> <img class="icon" src={require("../res/copy.png")}  alt="Copy"/> </div> </li>


    // <ul id="menu">
    //   <li className="dropdown">
    //     <div className="btnText" id="language">{Config.LANGUAGE.toUpperCase()}</div>
    //     <div className="button" onClick={() => setLanguage('fr')}><div className='btnText btnTextChoice'>FR</div></div>
    //     <div className="button" onClick={() => setLanguage('en')}><div className='btnText btnTextChoice'>EN</div></div>
    //     <div className="button" onClick={() => setLanguage('es')}><div className='btnText btnTextChoice'>ES</div></div>
    //
    //     <div id="languageDropdown" className="dropdown-content">
    //       </div>
    //   </li>
    //   <li><div id="clear" className="button" onClick={() => clear()}><img className="icon" src={require("../res/erase.png")}  alt="Copy"/></div> </li>
    //   <li><div id="share" className="button btn" data-clipboard-text="1" onClick={(e) => saveImg(e) }> <img className="icon" src={require("../res/download.png")}  alt="Copy"/> </div> </li>
    // </ul>

    return (
      <div className="menuContainer">
      <div className="menu">

         <div className="menu-button" onClick={() => clear()}>
            <img className="icon" src={require("../res/erase.png")}  alt="Copy"/>
         </div>

         <div className="menu-button" onClick={(e) => saveImg(e)}>
            <img className="icon" id="share" src={require("../res/download.png")}  alt="Copy"/>
         </div>

         <div className="menu-button" onClick={(e) => changeVibration()}>
            <img className="icon" id="vibration" src={require("../res/vibr_unmute.png")}  alt="Copy"/>
         </div>

         <div className="menu-button menu-button-right" onClick={() => showTuto()}>
            <img className="icon" id="question" src={require("../res/question.png")}  alt="Copy"/>
         </div>

         <div className="menu-button menu-button-right" onClick={() => setLanguage('es')}>
            <div id="es" className={isES()}> ES </div>
         </div>

         <div className="menu-button menu-button-right" onClick={() => setLanguage('fr')}>
            <div  id="fr" className={isFR()}> FR </div>
         </div>

         <div className="menu-button menu-button-right" onClick={() => setLanguage('en')}>
            <div id="en" className={isEN()}> EN </div>
         </div>



      </div>
      </div>
    );

}

export default Menu;
