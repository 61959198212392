var typingTimer;
var matrixInterval;
var displayTimer;
var caretTimer;

var blendForce = 1;
var matrixLength = 30;
var isCalling = 0;

const Timers = {caretTimer, typingTimer, matrixInterval, blendForce, matrixLength, isCalling, displayTimer};

export default Timers;
