import ResponsiveController from "./Scripts/ResponsiveController";
import Handlers from "./Scripts/Handlers";

function APIContainer(){


  ResponsiveController.desactivateAll();
  document.documentElement.style.overflowX = 'hidden';
  document.documentElement.style.overflowY = 'auto';

  Handlers.addAllWindowListeners();

  setTimeout(
    ()=> {
      ResponsiveController.disableTextArea();
    },1
  );

  return (
    <div className="APIContainer">

      <div id="couplet">
        <div id="prompted">
            <div className="line" id="line0">
              <div id="emptyChar" className="letter">&nbsp;</div>
              <div id="caret"></div>
            </div>
        </div>
      </div>

      <div id="bottomPage">
        <textarea id="hiddenInputArea" autoFocus type='text' rows='1' cols='1' onChange={Handlers.areaHandleChange}/>
      </div>

      <audio preload="auto" id="audio" src={require("../res/Eraser_sound.mp3")}></audio>

    </div>
  );
}


export default APIContainer;
