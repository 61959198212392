import Timers from "./Timers";

function replaceAt(s, index, replacement) {
    return s.substring(0, index) + replacement + s.substring(index + replacement.length);
}

function randomBlendText(displaySize, original) {
  var characters = '1234567890azertyuiopqsdfghjklmwxcvbnAZERTYUIOPQSDFGHJKLMWXCVBN+&é()-è_çà';//'=~#{}[]|`\\^@€¨£$¤*µ%ù?,.;/:!§';
  var charactersLength = characters.length;
  Timers.blendForce+=1;
  let result = "";
  for ( var i = 0; i < Math.min(displaySize,original.length); i++ ) {
        let bet = Math.floor(Math.random() * Timers.blendForce);
        let rdChar = characters.charAt(Math.floor(Math.random() * charactersLength));
        if (bet === 0 && original.charAt(i) !== ' ' && original.charAt(i) !== '\n'){
          original = replaceAt(original,i,rdChar);
        }
        else {}
        result += original.charAt(i);
  }
  return result;
}


function randomText(size) {
  var result = '';
  var characters = ' azertyuiopqsdfghjklmwxcvbnAZERTYUIOPQSDFGHJKLMWXCVBN1234567890°+&é()-è_çà=~#{}[]|`\\^@€¨£$¤*µ%ù?,.;/:!§';
  var charactersLength = characters.length;

  for ( var i = 0; i < size; i++ ) {
        let spaces = Math.floor(Math.random() * 5); // 1 chance sur 5
        if (spaces === 0) {
            result += ' ';
        }
        else {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
  }
  return result;
}

const RandomTextGenerator = {randomText, randomBlendText}
export default RandomTextGenerator;
