import Letters from "./Letters";
import Config from "./Config";

var PREV_AREA_VALUE = "";
var CURR_AREA_VALUE = "";

function desactivateAll(){
  document.oncontextmenu = () => false ;
}

function forceFocus(){
  document.getElementById("hiddenInputArea").focus();
}

function enableTextArea(){
  document.getElementById("hiddenInputArea").disabled = false;
  forceFocus();
}

function disableTextArea(){
  document.getElementById("hiddenInputArea").disabled = true;
  document.activeElement.blur();
}

function emptyString(n){
  let res = '';
  for (let i=0; i<n; i++){
    res += '‎';
  }
  return res;
}

function setCaretToEnd(){
  let end = document.getElementById("hiddenInputArea").value.length;
  document.getElementById("hiddenInputArea").setSelectionRange(end, end);
}

function addPrefixString(n){
  let res = emptyString(n);
  document.getElementById("hiddenInputArea").value = res + document.getElementById("hiddenInputArea").value
  setCaretToEnd();
}



function updateCaret(){

  try {
    document.getElementById("caret").remove();
  }
  catch {}

  finally {

    try {
      document.getElementById("line"+(Letters.LastLineID)).innerHTML += '<div id="caret"></div>';
      document.getElementById("caret").classList.add("explose");
    } catch {}

  }




  return;

}

function getCoord(id){
    let divposition = document.getElementById(id).getBoundingClientRect();
    return [divposition.right, divposition.top, divposition.left, divposition.bottom];
}

function changeCoord(id,x){
  let divposition = document.getElementById(id);
  divposition.style.position = 'sticky';
  divposition.style.top = "3vh";
  divposition.style.left = "50vw";
  divposition.style.bottom = "0vh";
  divposition.style.right = "0vh";
}

function isOffScreen(id){
  let divposition = document.getElementById(id).getBoundingClientRect();
  let res = false;
  let margin = Config.SCREEN_WIDTH > 400 ? 100 : 30 ;
  if( divposition.right > Config.SCREEN_WIDTH - margin) {
    res = true; // element is off to the right of the view
  }

  return res;
}


function newLineFromPrevious(isRealLine){

  if (isRealLine){

    let decalage = 1;
    for (let i=Letters.LastLetterID; i>=-1; i--){
      if (i===-1){
        decalage = 1;
        break;
      }
      let child = document.getElementById("letter"+i);
      if (child.parentNode.id!=="line"+Letters.LastLineID){
        decalage = 1;
        break;
      }
      if (child.innerHTML === '&nbsp;'){
        if (child.parentNode.firstChild === child){
          decalage = 1;
          break;
        }
        if (child.parentNode.firstChild.innerHTML === Config.NEW_LINE_CHAR && child.parentNode.children.item(1) === child){
          decalage = 1;
          break;
        }
        break;
      }
      decalage++;
    }



    document.getElementById("prompted").innerHTML += "<div class=line id=line"+(++Letters.LastLineID)+"></div>";
    document.getElementById("line"+Letters.LastLineID).innerHTML += "<div class='letter' id='letterTmp'>"+Config.NEW_LINE_CHAR+"</div>";
    Letters.LastLetterID++;
    let new_parent = document.getElementById("line"+Letters.LastLineID);
    for (let i=Letters.LastLetterID-decalage; i<Letters.LastLetterID; i++){
      let child = document.getElementById("letter"+i);
      document.getElementById("letter"+i).id = "letter"+(i+1);
      new_parent.appendChild(child);
    }
    document.getElementById("letterTmp").id = "letter"+(Letters.LastLetterID-decalage);
    Letters.totalPrompt = (Letters.totalPrompt.slice(0, Letters.LastLetterID-decalage) + "\n" + Letters.totalPrompt.slice(Letters.LastLetterID-decalage))
    document.getElementById("hiddenInputArea").value = Letters.totalPrompt;


  }

  else {
    // TODO
    let decalage = 1;
    for (let i=Letters.LastRLetterID; i>=Letters.LastLetterID; i--){
      if (i===Letters.LastLetterID){
        decalage = 1;
        break;
      }
      let child = document.getElementById("letter"+i);
      if (child.parentNode.id!=="line"+Letters.LastRLineID){
        decalage = 1;
        break;
      }
      if (child.innerHTML === '&nbsp;'){
        if (child.parentNode.firstChild === child){
          decalage = 1;
          break;
        }
        if (child.parentNode.firstChild.innerHTML === Config.NEW_LINE_CHAR && child.parentNode.children.item(1) === child){
          decalage = 1;
          break;
        }
        break;
      }
      decalage++;
    }

    document.getElementById("prompted").innerHTML += "<div class=line id=line"+(++Letters.LastRLineID)+"></div>";
    document.getElementById("line"+Letters.LastRLineID).innerHTML += "<div class='letter' id='letterTmp'>"+Config.NEW_LINE_CHAR+"</div>";
    Letters.LastRLetterID++;
    let new_parent = document.getElementById("line"+Letters.LastRLineID);
    for (let i=Letters.LastRLetterID-decalage; i<Letters.LastRLetterID; i++){
      let child = document.getElementById("letter"+i);
      document.getElementById("letter"+i).id = "letter"+(i+1);
      new_parent.appendChild(child);
    }
    document.getElementById("letterTmp").id = "letter"+(Letters.LastRLetterID-decalage);
  }

}

const ResponsiveController = {desactivateAll, disableTextArea, enableTextArea, changeCoord, getCoord, newLineFromPrevious, isOffScreen, setCaretToEnd, emptyString, updateCaret, addPrefixString,forceFocus, PREV_AREA_VALUE, CURR_AREA_VALUE};

export default ResponsiveController;
