import "./App.css";
import Home from "./pages/Home";
import Config from "./pages/Scripts/Config";
import Letters from "./pages/Scripts/Letters";
import ResponsiveController from "./pages/Scripts/ResponsiveController";
import Handlers from "./pages/Scripts/Handlers";

function App() {

  // Init language

  Letters.allowInput = false;

  console.log("PC",Config.REAL_KEYBOARD);
  console.log("Nav",Config.BROWSER_NAME);

  let mozillaStatus = messageMozilla();

  var userLang = navigator.language || navigator.userLanguage;
  if (userLang.charAt(0) === 'e' && userLang.charAt(1) === 'n'){
      Config.LANGUAGE = 'en';
  }
  else if (userLang.charAt(0) === 'f' && userLang.charAt(1) === 'r'){
      Config.LANGUAGE = 'fr';
  }
  else if (userLang.charAt(0) === 'e' && userLang.charAt(1) === 's'){
      Config.LANGUAGE = 'es';
  }
  else {
      Config.LANGUAGE = 'en';
  }


  function messageMozilla(){
    if (Config.BROWSER_NAME === "Mozilla Firefox" && Config.LANGUAGE === 'fr'){
      return "Nous détectons que vous utilisez le navigateur Mozilla Firefox. Ce site web ne fonctionnera pas correctement sur la version Android de ce navigateur.";
    }
    if (Config.BROWSER_NAME === "Mozilla Firefox" && Config.LANGUAGE === 'en'){
      return "We detect that you are using the Mozilla Firefox browser. This website will not function properly on the Android version of this browser.";
    }
    if (Config.BROWSER_NAME === "Mozilla Firefox" && Config.LANGUAGE === 'es'){
      return "Detectamos que está utilizando el navegador Mozilla Firefox. Este website no funcionará correctamente en la versión de Android de este navegador.";
    }
    else {
      return "";
    }
  }

  let hidePopup = () => {
      Letters.allowInput = false;
      let tmp = Config.REAL_KEYBOARD;
      Config.REAL_KEYBOARD = false;
      document.getElementById("hiddenInputArea").value = "";
      Handlers.areaHandleChange();
      Config.REAL_KEYBOARD = tmp;
      document.getElementById("msa-popup1").style.visibility = "hidden";
      document.getElementById("msa-popup1").style.opacity = "0";
      document.getElementById("msa-popup2").style.visibility = "visible";
      document.getElementById("msa-popup2").style.opacity = "1";
      //Letters.allowInput = true;
      //ResponsiveController.addPrefixString(0);
      //ResponsiveController.enableTextArea();
      //ResponsiveController.forceFocus();
  }

  let hidePopup2 = () => {
      //let tmp = Config.REAL_KEYBOARD;
      //Config.REAL_KEYBOARD = false;
      //document.getElementById("hiddenInputArea").value = "";
      //Handlers.areaHandleChange();
      //Config.REAL_KEYBOARD = tmp;
      document.getElementById("msa-popup2").style.visibility = "hidden";
      document.getElementById("msa-popup2").style.opacity = "0";

      if (Config.FIRST_TIME_TUTO){
        Letters.allowInput = true;
        ResponsiveController.addPrefixString(0);
        ResponsiveController.enableTextArea();
        ResponsiveController.forceFocus();
      }
      Config.FIRST_TIME_TUTO = false;
      Config.TUTO_OPEN = false;
      if (!Config.CARET_REMOVED){
        Letters.allowInput = true;
        ResponsiveController.enableTextArea();
        ResponsiveController.forceFocus();
      }

      //ResponsiveController.addPrefixString(0);
      //ResponsiveController.enableTextArea();
      //ResponsiveController.forceFocus();
  }

  //document.getElementById("msa-popup2").style.visibility = "hidden";
  //document.getElementById("msa-popup2").style.opacity = "0";

  return (


    <div className="App">
      <Home />
      <div id="msa-popup1" className="msa-overlay">
      	<div className="msa-popup">
          <h1>GRAT</h1>
          <h3>{Config.DISCLAIMER_TITLE(Config.LANGUAGE)}</h3>
          <h2>{Config.DISCLAIMER_TITLE_2(Config.LANGUAGE)}</h2>
          <hr className="rounded"/>
          <div className="msa-content">
            <p className="redMessage">{mozillaStatus}</p>
            <p>{Config.DISCLAIMER_CONTENT_1(Config.LANGUAGE)}</p>
            <p>{Config.DISCLAIMER_CONTENT_2(Config.LANGUAGE)}</p>
            <p>{Config.DISCLAIMER_CONTENT_3(Config.LANGUAGE)}</p>
            <button id='compris' className="msa-close" onClick={hidePopup}>{Config.COMPRIS(Config.LANGUAGE)}</button>

            <hr/>

            <p className="twitterParagraph">
              {Config.CREATED_BY(Config.LANGUAGE)} <a className="twitterLink" href="https://twitter.com/app_grat">@app_grat</a>
              <a className="twitterLink" href="https://twitter.com/app_grat">
              &nbsp; <img className="buttonTexth2" src={require("./res/twitter-mini.png")} alt="twitter"/>
              </a>
            </p>
          </div>

      	</div>
      </div>


      <div id="msa-popup2" className="msa-overlay">
      	<div className="msa-popup">
          <h2>
          <img className="buttonTexth2" src={require("./res/question.png")} alt="tutorial grat.app GRAT artificial intelligence rap poems songs generator"/>
          </h2>
      		<h2>
          &nbsp; {Config.TUTO_TITLE(Config.LANGUAGE)} &nbsp;
          </h2>
          <button id='compristuto' className="msa-close" onClick={hidePopup2}>{Config.COMPRIS_TUTO(Config.LANGUAGE)}</button>
          <hr className="rounded"/>

          <div className="msa-content">

          <div className="figureContain">

            <figure className="tuto">
              <p>{Config.TUTO_TYPE(Config.LANGUAGE)}</p>
              <img className="imgtuto" src={require("./res/tuto2bis.jpeg")} alt=""/>
              <p></p>
              <img className="imgtuto" src={require("./res/tuto2.jpeg")} alt=""/>
            </figure>

            <figure className="tuto">
            <hr/>
              <p>{Config.TUTO_WAIT_1(Config.LANGUAGE)}</p>
              <p className="red">{Config.TUTO_WAIT_2(Config.LANGUAGE)}</p>
              <p>{Config.TUTO_WAIT_3(Config.LANGUAGE)}</p>
              <img className="imgtuto" src={require("./res/tuto5.jpeg")} alt=""/>
            </figure>

            <figure className="tuto">
            <hr/>
              <p>{Config.TUTO_GENERATION(Config.LANGUAGE)}</p>
              <img className="imgtuto" src={require("./res/tuto6.jpeg")} alt=""/>
            </figure>

            <figure className="tuto">
            <hr/>
              <p>{Config.TUTO_SCREENSHOT(Config.LANGUAGE)} <img className="buttonText" src={require("./res/download.png")} alt=""/></p>
              <img className="imgtuto" src={require("./res/tuto7.jpeg")} alt=""/>
              <p></p>
              <img className="imgtuto" src={require("./res/tuto8.jpeg")} alt=""/>

            </figure>




            <figure className="tuto">
            <hr/>
              <p>{Config.TUTO_ERASE(Config.LANGUAGE)} <img className="buttonText" src={require("./res/erase.png")} alt=""/></p>
              <img className="imgtuto" src={require("./res/tuto4.jpeg")} alt=""/>
            </figure>


            <figure className="tuto">
            <hr/>
              <p>{Config.TUTO_EFFECT(Config.LANGUAGE)} <img className="buttonText" src={require("./res/vibr_unmute.png")} alt=""/>
              </p>

              <img className="imgtuto" src={require("./res/tuto3.jpeg")} alt=""/>

              <p className="tutoEffects">
                {Config.TUTO_EFFECT_BUTTON_0(Config.LANGUAGE)}
              </p>

              <p className="tutoEffects">
                <img className="buttonText" src={require("./res/vibr_unmute.png")} alt=""/> {Config.TUTO_EFFECT_BUTTON_1(Config.LANGUAGE)}
              </p>

              <p className="tutoEffects">
                <img className="buttonText" src={require("./res/unvibr_unmute.png")} alt=""/> {Config.TUTO_EFFECT_BUTTON_2(Config.LANGUAGE)}
              </p>

              <p className="tutoEffects">
                <img className="buttonText" src={require("./res/vibr_mute.png")} alt=""/> {Config.TUTO_EFFECT_BUTTON_3(Config.LANGUAGE)}
              </p>

              <p className="tutoEffects">
                <img className="buttonText" src={require("./res/unvibr_mute.png")} alt=""/> {Config.TUTO_EFFECT_BUTTON_4(Config.LANGUAGE)}
              </p>

            </figure>




            <figure className="tuto">
            <hr/>
              <p>{Config.TUTO_LANG(Config.LANGUAGE)}</p>
              <img className="imgtuto"  src={require("./res/tuto1.jpeg")} alt=""/>
              <p className="tutoEffects"> English (EN) </p><p className="tutoEffects">  Français (FR) </p><p className="tutoEffects">  Español (ES)</p>
            </figure>

            <figure className="tuto">
            <hr/>
              <p>{Config.TUTO_TUTO(Config.LANGUAGE)} <img className="buttonText" src={require("./res/question.png")} alt=""/></p>
              <img className="imgtuto"  src={require("./res/tuto9.jpeg")} alt=""/>
            </figure>

            <hr/>

            <p className="twitterParagraph">
              {Config.CREATED_BY(Config.LANGUAGE)} <a className="twitterLink" href="https://twitter.com/app_grat">@app_grat</a>
              <a className="twitterLink" href="https://twitter.com/app_grat">
              &nbsp; <img className="buttonTexth2" src={require("./res/twitter-mini.png")} alt="twitter"/>
              </a>
            </p>


          </div>

          </div>

      	</div>
      </div>

    </div>
  );
}

export default App;
