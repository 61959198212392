import Config from "./Config";

const NOT_LETTERS_CODE = {
  'Escape' : '',
  'F1' : '',
  'F2' : '',
  'F3' : '',
  'F4' : '',
  'F5' : '',
  'F6' : '',
  'F7' : '',
  'F8' : '',
  'F9' : '',
  'F10' : '',
  'F11' : '',
  'F12' : '',
  'Insert' : '' ,
  'PrintScreen' : '' ,
  'Delete' : '' ,
  'Backquote' : '' ,
  'Backspace' : '' ,
  'Home' : '' ,
  'Tab' : '' ,
  'PageUp' : '' ,
  'CapsLock' : '' ,
  'Enter' : '' ,
  'PageDown' : '' ,
  'Shift' : '' ,
  'ShiftLeft' : '' ,
  'ShiftRight' : '' ,
  'End' : '' ,
  'Control' : '' ,
  'ControlLeft' : '' ,
  'Alt' : '' ,
  'AltGraph' : '' ,
  'AltLeft' : '' ,
  'AltRight' : '' ,
  'ArrowLeft' : '' ,
  'ArrowUp' : '' ,
  'ArrowDown' : '' ,
  'ArrowRight' : '' ,

}


function getLastLineLength(entry){
  let allLines = entry.split('\n')
  let lastLine = allLines[allLines.length-1];
  return lastLine.length;
}

function preprocess(entry,response){

    let res = "";
    let n_char = getLastLineLength(entry);
    let max = Config.LINE_CHAR_LIMIT - 10;

    let cutNextSpace = false;
    if (n_char >= max){
      cutNextSpace = true;
    }

    for (let i=0; i<response.length; i++){

      if (cutNextSpace){
        if (response.charAt(i) === '\n' || response.charAt(i) === ' '){
          res += '\n';
          cutNextSpace = false;
          n_char = 0;

        } else {
          if (n_char>=Config.LINE_CHAR_LIMIT+10){
            res += '\n';
            cutNextSpace = false;
            n_char = 0;
          } else {
            n_char++;
          }
          res += response.charAt(i);
        }
      }

      else {

        if (response.charAt(i) === '\n'){
          n_char = 0;
        }
        else n_char++;

        if (n_char >= max){
          cutNextSpace = true;
          n_char++;
        }
        res += response.charAt(i);
      }
    }
    return res;
}


var LastLetterID = -1;
var LastLineID = 0;

var LastRLetterID = -1;
var LastRLineID = 0;

var totalPrompt = "";
var allowInput = true;
var emptyPage = true;
var shakeChoice = 5;




var test = 0;

const prediction0 = Config.DEFAUT_PRED(Config.LANGUAGE);
const prediction1 = "";
const prediction2 = prediction0;


const Letters = {prediction2, LastRLetterID, LastRLineID, getLastLineLength, preprocess, NOT_LETTERS_CODE, shakeChoice, emptyPage, LastLetterID, LastLineID, totalPrompt, prediction0, prediction1, test, allowInput};

export default Letters;
