import Config from "./Config";

function getLastWordIdx(text){
  let end = text.length-1;
  while (text.charAt(end) === ' '){
    end--;
  }

  let idx = end;

  while( text.charAt(idx) !== ' ' && text.charAt(idx) !== '\n' && idx >= 0){
    idx--;
  }

  return [(idx+1),(text.length - (idx + 1))];

}

function getModifKeys(id, nb_backspases,text){
  // let tmp = getLastWordIdx(text);
  // let id = tmp[0];
  // let nb_backspases = tmp[1];
  let res = []

  for (let i=0; i<nb_backspases; i++){
    res.push('Backspace');
  }

  for (let i=id; i<text.length; i++){
    res.push(text.charAt(i));
  }

  return res;

}

function predictKey(prev,curr){

  if ( prev.length > curr.length ){
    let res = [];
    for (let i=0; i<prev.length-curr.length; i++){
      res.push("Backspace");
    }
    return res;
  }

  if (prev.length < curr.length) {
    if (curr[curr.length-1] === ' '){
      if (prev === Config.lastPred) return [" "]
      let tmp = getLastWordIdx(prev);
      let id = tmp[0];
      let nb_backspases = tmp[1];
      return getModifKeys(id,nb_backspases,curr);
    }
    let res = [];
    for (let i=prev.length; i<curr.length; i++){
      if (curr.charAt(i) === '\n') res.push('Enter');
      else res.push(curr.charAt(i));
    }
    return res;
  }

  if (prev.length === curr.length){
    let tmp = getLastWordIdx(prev);
    let id = tmp[0];
    let nb_backspases = tmp[1];
    return getModifKeys(id,nb_backspases,curr);
  }

  else {
    return ["Shift"];
  }

}

const KeyPredictor = {predictKey, getLastWordIdx, getModifKeys}
export default KeyPredictor;
