
function Header() {
  return (
    <header>
      <h1><div id="GRAT">GRAT</div></h1>
    </header>
  );
}

export default Header;
