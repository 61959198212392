import Config from "./Config";
import Letters from "./Letters";
import Timers from "./Timers";
import ResponsiveController from "./ResponsiveController";

function HandleKeyDown(e_key){

  if (!Letters.allowInput){
    return;
  }

  // Remove EmptyChar ==================================
  try{
    document.getElementById("emptyChar").remove();
  } catch {}

  // Animation effect ==================================
  clearTimeout(Timers.typingTimer);
  clearTimeout(Timers.displayTimer);
  clearTimeout(Timers.matrixInterval);
  clearTimeout(Timers.caretTimer);

  // Text editor implementation =======================


  if (Letters.NOT_LETTERS_CODE[e_key] === undefined){

     if (
      (document.getElementById("line"+Letters.LastLineID).childElementCount === Config.LINE_CHAR_LIMIT)
      ||
      (document.getElementById("line"+Letters.LastLineID).childElementCount === Config.LINE_CHAR_LIMIT-1 && Letters.LastLineID===0)
      ){
       document.getElementById("hiddenInputArea").value+='\n';
       ResponsiveController.CURR_AREA_VALUE = document.getElementById("hiddenInputArea").value;
       addDivForLine();
      }
     // if ( (Letters.LastLetterID - Letters.LastLineID * Config.LINE_CHAR_LIMIT) % Config.LINE_CHAR_LIMIT === Config.LINE_CHAR_LIMIT-1 ){
     //   addDivForLine();
     // }

     //ResponsiveController.addPrefixString();
     //let propag = "‎";
     //for (let i=0; i<10; i++) propag+="‎";
     //document.getElementById("hiddenInputArea").value+=ResponsiveController.emptyString(0);
     ResponsiveController.CURR_AREA_VALUE = document.getElementById("hiddenInputArea").value;
     ResponsiveController.forceFocus();

     addDivForLetter(e_key);
     Letters.emptyPage = false;

  }

  else {

    clearTimeout(Timers.typingTimer);
    switch (e_key){

      case 'Backspace' :
        removeDiv();
        break;

      case 'Enter' :
        addDivForLine();
        break;

      default :
        break;

    }
  }



  if (e_key !== "Backspace"){
    if (Config.VIBRATION) document.getElementById("GRAT").classList.add("is_shaking"+1);
    if (Config.VIBRATION) document.getElementById("prompted").classList.add("is_shaking"+1);
  }

  if (Letters.LastLetterID >= 0 && ResponsiveController.isOffScreen(   "letter"+(Letters.LastLetterID)   ))  {
    ResponsiveController.newLineFromPrevious(true);
    ResponsiveController.PREV_AREA_VALUE = Letters.totalPrompt;
    ResponsiveController.CURR_AREA_VALUE = Letters.totalPrompt;
  };
  
  ResponsiveController.updateCaret();
  let x = ResponsiveController.getCoord('caret');
  ResponsiveController.changeCoord("hiddenInputArea",x);


  if (Letters.totalPrompt === ''){
    Letters.emptyPage = true;
    document.getElementById("line0").innerHTML += "<div id='emptyChar' class='letter'>&nbsp;</div>";
    document.getElementById("caret").remove();
    document.getElementById("line0").innerHTML += "<div id='caret'></div>";
  }

};

function addDivForLetter(e_key){
  //document.getElementById("prompted").innerHTML += "'"+e.code+"' : '' ,\n";
  //Letters.LastLetterID++;
  if (e_key === 'Dead'){
    return;
  }
  if (e_key !== ' '){
    document.getElementById("line"+Letters.LastLineID).innerHTML += "<div class=letter id=letter"+(++Letters.LastLetterID)+">"+e_key+"</div>";
    Letters.totalPrompt += e_key;
  }

  else {
    document.getElementById("line"+Letters.LastLineID).innerHTML += "<div class=letter id=letter"+(++Letters.LastLetterID)+">&nbsp;</div>";
    Letters.totalPrompt += ' ';
  }

}

function addDivForLine(){
  document.getElementById("prompted").innerHTML += "<div class=line id=line"+(++Letters.LastLineID)+"></div>";
  document.getElementById("line"+Letters.LastLineID).innerHTML += "<div class=letter id=letter"+(++Letters.LastLetterID)+">"+Config.NEW_LINE_CHAR+"</div>";
  Letters.totalPrompt += '\n';
}

function removeDiv(){
  try {
    if (document.getElementById("letter"+Letters.LastLetterID).innerHTML === Config.NEW_LINE_CHAR){
      document.getElementById("line"+Letters.LastLineID).remove();
      Letters.LastLineID--;
      Letters.LastLetterID--;
    }
    else {
      document.getElementById("letter"+Letters.LastLetterID).remove();
      Letters.LastLetterID--;
    }
  } finally {
    Letters.totalPrompt = Letters.totalPrompt.slice(0,-1);
    return;
  }
}

export default HandleKeyDown;
