function processPrediction(totalPrompt, prediction){
  if ( totalPrompt.charAt(totalPrompt.length-1) === ' ' || totalPrompt.charAt(totalPrompt.length-1) === '\n'){
    return prediction.trim();
  }
  return prediction.trimEnd();
}

const Preprocess = {processPrediction};

export default Preprocess;
